import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://be.videoportal.gloster.hu/api/v1/";

const getUserData = () => {
    return axios.get(API_URL + "get-admin", {headers: authHeader()})
}
const getAdmins = () => {
    return axios.get(API_URL + 'admins', {headers: authHeader()})
}
const deleteAdmin = id => {
    return axios.delete(API_URL + "delete-admin/" + id, {headers: authHeader()})
}
const addAdmin = email => {
    return axios.post(API_URL + 'add-admin', {email}, {headers: authHeader()})
}
const getCategories = () => {
    return axios.get(API_URL + 'categories', {headers: authHeader()})
}
const deleteCategory = id => {
    return axios.delete(API_URL + "delete-category/" + id, {headers: authHeader()})
};
const addCategory = name => {
    return axios.post(API_URL + 'add-category', {name}, {headers: authHeader()})
};
const getCategory = id => {
    return axios.post(API_URL + 'get-category', {id}, {headers: authHeader()})
}
const editCategory = (id, name) => {
    return axios.patch(API_URL + "update-category/" + id, {name}, {headers: authHeader()})
}
const getVideos = () => {
    return axios.get(API_URL + "admin/videos", {headers: authHeader()})
}
const getYears = () => {
    return axios.get(API_URL + "admin/get-years", {headers: authHeader()})
}
const filterVideos = filters => {
    return axios.get(API_URL + "admin/videos", {params: filters, headers: authHeader()})
}
const addVideo = (formData) => {
    return axios.post(API_URL + "add-video",
        formData
        , {
            headers: {
                'content-type': 'multipart/form-data',
                ...authHeader()
            },
        }
    )
}

const getVideoDataBySlug = (title) => {
    return axios.get(API_URL + "get-video/" + title, {headers: authHeader()})
}

const EditVideo = (id, formData) => {
    return axios.post(API_URL + "update-video/" + id, formData, {
        headers: {
            'content-type': 'multipart/form-data',
            ...authHeader()
        }
    })
}

const deleteVideo = id => {
    return axios.delete(API_URL + "delete-video/" + id, {headers: authHeader()})
}

const shareVideos = (notification_type, video_ids) => {
    return axios.post(API_URL + 'notify', {notification_type, video_ids}, {headers: authHeader()})
}

const userService = {
    getUserData,
    getAdmins,
    deleteAdmin,
    addAdmin,
    getCategories,
    deleteCategory,
    addCategory,
    getCategory,
    editCategory,
    getVideos,
    getYears,
    filterVideos,
    addVideo,
    getVideoDataBySlug,
    EditVideo,
    deleteVideo,
    shareVideos
}
export default userService

