import {useState, useEffect, useCallback} from "react";
import userService from "../services/user-services";

function useFetch(query, page) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [list, setList] = useState([]);

    const sendQuery = useCallback(async () => {
        try {
            await setLoading(true);
            await setError(false);
            const res = await userService.filterVideos({...query, page: page});

            await setList((prev => page !== 1 ? [...prev, ...res.data.videos.data] : [...res.data.videos.data]));
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    }, [query, page]);


    useEffect(() => {
        sendQuery(query);
    }, [query, sendQuery, page]);

    return {loading, error, list};
}

export default useFetch;
